@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  background-color: #fafbfc;
  overflow-y: hidden;
  overflow-x: hidden;
}

::-webkit-scrollbar{
  background-color: transparent;
  width: 8px;
  border-radius: 14px;
}

::-webkit-scrollbar-thumb{
  background-color: #d6d6d6;
  border-radius: 15px;
}

.flex-block{
  display: flex;
}

.flex-none{
  display: flex;
}

.block-flex{
  display: block;
}

.block-none{
  display: block;
}

.none-flex{
  display: none;
}

@media screen and (max-width: 740px) {
  .flex-block{
    display: block;
  }
  .flex-none{
    display: none !important;
  }
  .none-flex{
    display: flex !important;
  }
  body{
    overflow-y: scroll !important;
  }
  .block-none{
    display: none !important;
  }
  .block-flex{
    display: flex;
  }
  .buttons{
    padding-left: 0px !important;
    padding-top: 24px;
  }
  .button{
    padding-left: 0px !important;
    padding-top: 14px;
  }
}

.buttons{
  padding-left: 34px;
}

.button{
  padding-left: 14px;
}